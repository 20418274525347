import { useState } from 'react';
import ReactDOM from 'react-dom/client';

export default function Payment() {
  const [name, setName] = useState("");
  setTimeout(() => {
    document.redirect.submit();
}, 500);
  return (
    <form method="post" name="redirect" action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction">

      <input type="text" id="encRequest" name="encRequest" value="d1a45df28795ac0cf56cb144bdc27bcbeda4738c99e102261786078a31dc645091446b246ff71df260bc448dbd6b69f720d4c99078ae033ad51c476b4978779df5768ced2ae729594ff566eda327093a15fd7cd98dfbea44ec0fbb54307798de3c8b6d8019724b45a9c987c8dd89b3f52bd6d29807f82d03249532437f79f87bd90288f3419e7eea4f74bcb33fa41aaf33c46517b3eb9f960593819369365c33b6b628398db53a4afd4a32ee6a94b7bf52f7227956be0ee27f1cd2001a71599aa9fda00a3630d47b3300d0a796bcbbd1794590318e5e759efc5ca40c2757ace35f7fdc4cf813e5903e1b8fc340c277889370ff1889a3b7b64a3a52b287e94aa359ab0597f96795eb61b4bcc027d930eb7fc89e806a988a9f526f08f74a98e216fb45812685c5bf8c60e9229ec0146e189c36276d4c77442f1cd5c0baeadcba0c81dd93a85313d7d8f514615952a0b8caf7c3822c968b2d3f773eaf580314da40c2bca10c04f7eae177df32a7610223566f2769c6557c33ab226241cb5c76276b987e9c0d952ef1e5b0f501518d681ee957fc92f2ee52c424d841a16eb334bc925305519ab6d4442382ab1cac74c0ec3799d87eaafa2ba5b08b92f353edd2bbde39317babb6fb085a23f0e882330560380351c205d4d5d63f0581617254e8dbbafdaa78348b6ba7e1facdd901169d71b772ca5fb7e087d29a2bbfb207685a2438a9d628040af2c609e9b7c27a87a6700a3c327534158d19bcdbc5153a92909572"/>		
      <input type="text" name="access_code" id="access_code" value="AVEH05KJ27BG29HEGB"/>

    </form>
  )
}
