// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',

  type: 'group',
  children: [
    {
      id: 'util-typography',
      title: 'Browse Prompts',
      type: 'item',
      url: '/browse',
      icon: icons.IconShadow,
      breadcrumbs: false
    },
    {
      id: 'util-color',
      title: 'Add Prompt',
      type: 'item',
      url: '/utils/util-typography',
      icon: icons.IconShadow,
      breadcrumbs: false
    },
    {
      id: 'util-shadow',
      title: 'Profile',
      type: 'item',
      url: '/profile-page',
      icon: icons.IconShadow,
      breadcrumbs: false
    },
    {
      id: 'my-prompts',
      title: 'My Prompts',
      type: 'item',
      url: '/myprompts',
      icon: icons.IconShadow,
      breadcrumbs: false
    },
  ]
};

export default utilities;
