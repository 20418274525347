import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, FormControl, InputLabel, OutlinedInput, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import CollapsibleTable from 'ui-component/table/Table';
import { useAuth0 } from "@auth0/auth0-react";

export default function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState(false);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    if (isLoading) {
        return <div>Loading ...</div>;
      }
    const theme = useTheme();
    return (  isAuthenticated && (
        <div style={{minHeight:"650px",  marginTop: "5  0px"}}>
           
            <Accordion defaultExpanded={true} onChange={handleChange('panel1')}  sx={{backgroundColor:"white",marginBottom:"50px"}} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    defaultExpanded={true}
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h4">
                        Personal Information
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>Your profile information</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid direction="row" spacing={1}>
                        <Grid item xs={8} md={4}>
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-email-register">username</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="email"
                                    defaultValue={user.name}

                                    name="email"

                                    inputProps={{}}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={8} md={4}>   <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register">Primary Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="email"
                                
                                name="email"
                                value={user.email}
                                inputProps={{}}
                            />

                        </FormControl>
                        </Grid>
                        <Grid><Button variant="outlined">Update</Button></Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}  sx={{backgroundColor:"white"}} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h4">
                        Billing
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        All about the money!
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                     Yep, we are going to share with you soon!
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}  sx={{backgroundColor:"white"}} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h4">Profile Actions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid><Button variant="contained" color="error">Delete Account</Button></Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    ));
}
