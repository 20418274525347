import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProfilePage from 'views/pages/profile/Proflie';
import MyPrompts from 'views/pages/MyPrompts';
import MarketPlace from 'views/pages/marketplace';
import PublicProfile from 'views/pages/MyPrompts/PublicProflie';
import PublicProfilePage from 'views/pages/profile/publicProfile';
import PrivacyPolicy from 'views/pages/policy/privacyPolicy';
import TermsNServicesPolicy from 'views/pages/policy/tns';
import Payment from 'views/pages/payment/payment';
import PaymentSuccess from 'views/pages/payment/success';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },

    {
      path: 'prompt',
      element: <SamplePage />
    },
    {
      path: 'profile-page',
      element: <ProfilePage />
    },
    {
      path: 'myprompts',
      element: <MyPrompts />
    },
    {
      path: 'browse/:cat',
      element: <MarketPlace/>
    },
    {
      path: 'browse',
      element: <MarketPlace/>
    },
    {
      path: 'public-profile',
      element: <PublicProfilePage/>
    },
    {
      path:'privacy-policy',
      element: <PrivacyPolicy/>
    }
    ,
    {
      path:'terms',
      element: <TermsNServicesPolicy/>
    },
    {
      path:'payments',
    
      children: [
        {
          path: 'success',
          element: <PaymentSuccess />
        },
        {
          path: 'init',
          element: <Payment/>
        }
      ]
    }
  ]
};

export default MainRoutes;
