import React from "react";

import { makeStyles } from "@mui/styles";
import {Select, MenuItem,FormControl} from "@mui/material"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "#6EC177",
      borderColor: "#6EC177",
      borderWidth: "0px",
      borderStyle: "solid",
      borderRadius: "10px",
      minWidth: "100px",
      maxHeight: "50px",
      justifyContent: "center"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px"
    }
  },
  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  selectIcon: {

    color: "#69c6d5",
    fontSize: "18px"
  },
  paper: {
    borderRadius: 5,
    marginTop: 4
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
   
      fontSize: "12px"
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#00507d"
    },
    "& li.Mui-selected:hover": {
      background: "#69c6d5"
    }
  }
}));

const CustomDropDown = ({ value, handleChange, items }) => {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },

    getContentAnchorEl: null
  };

  return (
   
      <Select
        value={value}
        onChange={handleChange}
        disableUnderline
        size="small"
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            {item.key}
          </MenuItem>
        ))}
      </Select>

  );
};

export default CustomDropDown;
