import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Paper, Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        marginTop: "auto",
        width: '100%',
        position: 'relative',
        bottom: 0,
        width: '100%',
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.secondary[200]
            : theme.palette.grey[800],



      }} component="footer" 
    >
      <Container maxWidth="lg" sx={{marginTop:"20px", marginBottom: "20px"}}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
          <Link href="" color="inherit">
            <Typography variant="h6" color="text.primary" gutterBottom>
             Add new prompts
            </Typography>
            </Link>
            <Link href="/browse" color="inherit">
            <Typography variant="h6" color="text.primary" gutterBottom>
             Broswe prompts
            </Typography>
            </Link>
            <Link href="/browse/fun" color="inherit">
            <Typography variant="h6" color="text.primary" gutterBottom>
             Fun prompts
            </Typography>
            </Link>
            <Link href="/browse/code" color="inherit">
            <Typography variant="h6" color="text.primary" gutterBottom>
             Code prompts
            </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
        
            <Typography variant="body2" color="text.secondary">
              Email: hello@goprompts.in
            </Typography>
       
          </Grid>
          <Grid item xs={12} sm={4}>
       
            <Link href="/privacy-policy" color="inherit">
            <Typography variant="h6" color="text.primary" gutterBottom>
             Privacy Policy
            </Typography>
            </Link>
          
      
       
       <Link href="/terms" color="inherit">
       <Typography variant="h6" color="text.primary" gutterBottom>
        Terms
       </Typography>
       </Link>
     
     </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.goprompts.in/">
             GoPrompts
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}