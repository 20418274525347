import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import React from "react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
    <Button  sx={{marginLeft: "30px"}} variant= {"outlined"}onClick={() => loginWithRedirect({ authorizationParams:{screen_hint: "signup"}, appState: { returnTo: window.location.pathname } })}>Sign Up</Button>

    <Button  sx={{marginLeft: "30px"}} variant= {"contained"}onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}>Log In</Button></>);
};

export default LoginButton;