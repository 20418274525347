import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import successImg from 'assets/images/4416.jpg'

export default function PaymentSuccess() {

  return (
   <img src={successImg}/>
  )
}
