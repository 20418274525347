// material-ui
import { Grid, Typography} from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';

import MyPromptsTab from "./TabMenu.js"
import CustomizedBreadcrumbs from 'ui-component/BreadCrumb.js';


// ==============================|| SAMPLE PAGE ||============================== //



const MyPrompts = () => (


<>
    <Grid   item
  container
 
  alignItems="center"
  justifyContent="center" direction={"row"} >


      <Grid item xs={12} md={8} spacing={2}>

        <Typography style={{marginTop: "30px", marginBottom: "30px"}}variant="h3" >  My Prompts</Typography>
     
        <MyPromptsTab></MyPromptsTab>

      </Grid>
     
    </Grid>

    </>
);

export default MyPrompts;
