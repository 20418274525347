import * as React from 'react';
import { useEffect } from 'react';
// material-ui
import { Grid, Stack, Divider, Box, Typography, IconButton, Zoom, FormControlLabel, FormLabel, FormGroup, RadioGroup, Chip, useMediaQuery } from '@mui/material';
import Grow from '@mui/material/Grow';
import UpgradePlanCard from 'layout/MainLayout/Header/ProfileSection/UpgradePlanCard';

// project imports

import { sortBy, models, categories, selectItems } from 'store/constant';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CustomDropDown from 'ui-component/checkbox/CustomDropDown';
import { useTheme } from '@mui/material/styles';;
import { useLocation,useParams} from "react-router-dom";

// ==============================|| SAMPLE PAGE ||============================== //


let startKeys = [];

const MarketPlace = ({inCategory}) => {

    const [prompts, setMyPrompts] = React.useState([]);
    const [selected, setSelected] = React.useState("");
    const [lastKey, setLastKey] = React.useState();
    const {state} = useLocation();
    const [sort, setSortBy] = React.useState("popular");
    let { cat } = useParams();
    const [category, setCategory] = React.useState(cat?cat:"code");
    const [model, setModel] = React.useState("ChatGPT");
    const [menuItems, setMenuItems] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true)
    const delay = 100;
    const theme = useTheme();
    const mobileview = useMediaQuery(theme.breakpoints.down('sm'));



    const getUserMetadata = (key, sortBy) => {
        setLoading(true)
        console.log(category)
        setMyPrompts([])
        try {
           
            console.log(key)
            console.log
            fetch(`https://bh2y51flh9.execute-api.us-east-1.amazonaws.com/staging/prompts/category?type=${encodeURIComponent(key.category)}&limit=${key.limit}&cursor=${encodeURIComponent(key.id)}&user=${encodeURIComponent(key.user)}&sortBy=${sortBy === "popular" ? "views" : "time"}&views=${key.views}&time=${key.time}`,
                {
                    method: 'GET',
                })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setMyPrompts(data);
                    setLastKey(data[data.length - 1])
                    setLoading(false)

                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err.message);
                });

        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {

        const breakArray = (x) => {
            return { key: x, value: x }
        }
        const createObj = (x) => {
            return x.map(breakArray)
        }
        setMenuItems(selectItems.map(createObj))
        let cat = category + "#" + model;
        let initKey = { "category": cat, "id": "", "user": "", "views": "", "time": "", limit: 6 }
        getUserMetadata(initKey, sort);
    }, []);

    const updateCatefory = (category) => {
        startKeys = [];
        setSelected(category);
        let initKey = { "category": category, "id": "", "user": "", "views": "", limit: 6 }
        console.log(category)
        getUserMetadata(initKey, sort);
    }

    const handleBefore = () => {
        console.log(startKeys)
        getUserMetadata(startKeys.pop(), sort)

    }
    const handleNext = () => {
        console.log(lastKey)
        let startKey = { "category": prompts[0].prompt_category, "user": prompts[0].PK, "id": prompts[0].SK, "views": prompts[0].views, limit: 6 }
        startKeys.push(startKey)
        let initKey = { "category": lastKey.prompt_category, "user": lastKey.PK, "id": lastKey.SK, "views": lastKey.views, limit: 6 }
        console.log(initKey)
        getUserMetadata(initKey, sort)
    }

    const handleSortSelect = (value) => {
        setSortBy(value)
        let cat = category + "#" + model;
        let initKey = { "category": cat, "id": "", "user": "", "views": "", "time": "", limit: 6 }
        getUserMetadata(initKey, value);
    }
    const handleModelSelect = (value) => {
        let cat = category + "#" + value;
        setModel(value)
        let initKey = { "category": cat, "id": "", "user": "", "views": "", "time": "", limit: 6 }
        getUserMetadata(initKey, sort);
    }
    const handleCatSelect = (value) => {
        let cat = value + "#" + model;
        setCategory(value)
        let initKey = { "category": cat, "id": "", "user": "", "views": "", "time": "", limit: 6 }
        getUserMetadata(initKey, sort);
    }

    const updateFilter = (event) => {
        console.log(event.target)
    }

    // const shouldDisableCheckbox = value => {
    //     // either from props: const { maxAllowed } = this.props
    //     // or from state: const { maxAllowed } = this.state
    //     // or just a constant:
    //     const maxAllowed = 3
    //     const { checked } = this.state
    //     return checked.length >= maxAllowed && checked.indexOf(value) === -1
    //  }

    return (
        <>
            <Grid item
                container
                spacing={4}
                alignItems="center"
                justifyContent="center" >
                <Grid item xs={12} md={8} lg={8} xl={8}>
                    <Typography style={{ marginTop: "30px", marginBottom: "30px" }} variant="h3" >  Browse Prompts</Typography>
                    {/* 
                    <Stack direction="row" spacing={1} >
                        {categories.map((option, index) => (
                            <Chip style={{ borderRadius: "1px" }} label={option} component="a" href="#basic-chip" clickable onClick={() => updateCatefory(option)}
                                variant={selected.match(option) ? "filled" : "outlined"} />
                        ))}

                    </Stack> */}

                    <Box margin={"50px"} sx={{ flexGrow: 1 }}>
                     <Grid container item spacing={4} columns={{ xs: 8, sm: 8, md: 12, lg: 12 }}>
                            <Divider orientation="horizontal" flexItem sx={{ mr: "-2px" }} />
                            {!mobileview && <><Grid item xs={4} sm={2} md={2}>
                                <Typography sx={{ marginTop: "30px" }} variant='h4'>Sort</Typography>

                                {sortBy.map((option, index) => (
                                    <Grid item xs={4} sm={4} md={12} sx={{ marginTop: "10px", marginTop: "10px" }}>
                                        <Chip style={{ borderRadius: "10px", fontSize: "11px" }} label={option} component="a" href="#basic-chip" clickable onClick={() => handleSortSelect(option)}
                                            variant={(sort === option) ? "contained" : "outlined"} />
                                    </Grid>

                                ))}
                                <Typography sx={{ marginTop: "30px" }} variant='h4'>Models</Typography>

                                {models.map((option, index) => (
                                    <Grid item xs={4} sm={4} md={12} sx={{ marginTop: "10px", marginTop: "10px" }}>
                                        <Chip style={{ borderRadius: "10px", fontSize: "11px" }} label={option} component="a" href="#basic-chip" clickable onClick={() => handleModelSelect(option)}
                                            variant={(model === option) ? "contained" : "outlined"} />
                                    </Grid>

                                ))}
                                <Typography sx={{ marginTop: "30px" }} variant='h4'>Category</Typography>

                                {categories.map((option, index) => (
                                    <Grid item xs={4} sm={4} md={12} sx={{ marginTop: "10px", marginTop: "10px" }}>
                                        <Chip style={{ borderRadius: "10px", fontSize: "11px" }} label={option} component="a" href="#basic-chip" clickable onClick={() => handleCatSelect(option)}
                                            variant={(category === option) ? "contained" : "outlined"} />
                                    </Grid>

                                ))}


                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ mr: "-2px" }} /> </>}
                            <Grid item xs={8} sm={2} md={10}>
                             {mobileview &&    <Grid container item spacing={2} sx={{ marginBottom: "30px" }}>
                                    {menuItems.map((item, index) => (
                                        
                                            <CustomDropDown value={item[0].key} handleChange={updateFilter}

                                                items={item} ></CustomDropDown>
                                      
                                    ))}
                                </Grid> }
                                <Grid container item spacing={2} columns={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                                    {isLoading && [1,2,3,4,5,6,7,8].map(val => (<>
                                        <Grid xs={4} sm={2} md={2} lg={2} xl={2}>
                                        <UpgradePlanCard promptObj={prompt} isLoading={isLoading}  ></UpgradePlanCard>
                                            </Grid>
                                            </>))}
                                    {!isLoading && prompts.map((prompt, index) => (
                                        <Grid xs={4} sm={2} md={2} lg={2} xl={2}>

                                            <Zoom in={true} style={{ transitionDelay: (delay * index).toString() + "ms" }}>
                                                <div>
                                                    <UpgradePlanCard promptObj={prompt} isLoading={isLoading} ></UpgradePlanCard>
                                                </div>
                                            </Zoom>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <IconButton label="Previous" disabled={startKeys.length == 0} onClick={handleBefore}><NavigateBeforeIcon></NavigateBeforeIcon></IconButton>
                                    <IconButton onClick={handleNext}><NavigateNextIcon></NavigateNextIcon></IconButton></Grid>
                            </Grid>
                        </Grid>

                    </Box>

                </Grid>

            </Grid>

        </>

    );
};

export default MarketPlace;
