import * as React from 'react';
import { useEffect } from 'react';
import PropTypes, { bool } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PromptList from './PromptList';
import { useAuth0 } from "@auth0/auth0-react";

import { Skeleton, IconButton, Stack, Chip } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { categories, selectItems } from 'store/constant';
import CustomDropDown from 'ui-component/checkbox/CustomDropDown';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let startKeys = [];
let shouldpush = true;
export default function MyPromptsTab() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [prompts, setMyPrompts] = React.useState([]);
  const [menuItems, setMenuItems] = React.useState([]);
  const [lastKey, setLastKey] = React.useState();
  const { user, getAccessTokenSilently } = useAuth0();



  const getUserMetadata = async (cursor) => {
    const domain = "shacklefree.uk.auth0.com";

    try {
      const accessToken = await getAccessTokenSilently();
      setLoading(true)
      fetch(`https://bh2y51flh9.execute-api.us-east-1.amazonaws.com/staging/prompts?userId=${user.email}&limit=5&cursor=${cursor}`,
        {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${accessToken}` }
        })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMyPrompts(data);
          setLastKey(data[data.length - 1].prompt_id)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });

    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {

    const breakArray= (x) =>{
      return {key:x,value:x}
    }
    const createObj = (x) => {
      return x.map(breakArray)      
    }
    setMenuItems(selectItems.map(createObj))
    getUserMetadata("");
    console.log(menuItems)
    console.log(startKeys)
  }, [getAccessTokenSilently, user?.sub]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBefore = () => {
    console.log(startKeys)
    getUserMetadata(startKeys.pop())

  }
  const handleNext = () => {
    console.log(lastKey)
    startKeys.push(prompts[0].prompt_id)
    getUserMetadata(lastKey)
  }

  const updateFilter = (event) => {
    console.log(event.target)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Created by Me" {...a11yProps(0)} />
          <Tab label="Purchased" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
   
        {!loading && <Stack direction="row" spacing={1} >
          {menuItems.map((item, index) => (
         
            <CustomDropDown value={item[0].key} handleChange={updateFilter}

              items={item}></CustomDropDown>
          ))}

        </Stack> }
        <PromptList myPrompts={prompts} isLoading={loading}></PromptList>
        <IconButton onClick={handleBefore} ><NavigateBeforeIcon></NavigateBeforeIcon></IconButton>
        <IconButton ><NavigateNextIcon onClick={handleNext}></NavigateNextIcon></IconButton>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
            <p>Coming Soon!</p>
      </CustomTabPanel>
    </Box>
  );
}
