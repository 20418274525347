// material-ui
import * as React from 'react';
import { useEffect } from 'react';
// project imports

import { useAuth0 } from "@auth0/auth0-react";

import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Avatar, IconButton, Chip, Divider, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';

import { categories } from 'store/constant';
import UpgradePlanCard from 'layout/MainLayout/Header/ProfileSection/UpgradePlanCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TotalIncomeLightCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// ==============================|| SAMPLE PAGE ||============================== //

let startKeys=[];

export default function PublicProfilePage() {
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [prompts, setMyPrompts] = React.useState([]);
    const [topPrompts, setTopPrompts] = React.useState([]);
    const [lastKey, setLastKey] = React.useState();
    const { user, getAccessTokenSilently } = useAuth0();
    const [stats, setMyStats] = useState({numPrompts:'0', numViews: '0'});
    const [expanded, setExpanded] = React.useState(false);
    const [copyTitle, setCopyTitle] = React.useState('copy');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const theme = useTheme();
    const getUserPrompts = async (cursor) => {
  
      try {
        const accessToken = await getAccessTokenSilently();
        setLoading(true)
        fetch(`https://bh2y51flh9.execute-api.us-east-1.amazonaws.com/staging/prompts?userId=${user.email}&limit=4&cursor=${cursor}`,
          {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${accessToken}` }
          })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setMyPrompts(data);
            setLastKey(data[data.length - 1].prompt_id)
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            setLoading(false);
          });
  
      } catch (e) {
        console.log(e.message);
      }
    };
    const getUserMetadata = async (cursor) => {
        const domain = "shacklefree.uk.auth0.com";
     
        try {
          const accessToken = await getAccessTokenSilently();
      
          fetch(`https://bh2y51flh9.execute-api.us-east-1.amazonaws.com/staging/user/profile?userId=${user.email}`,
          {method:'GET',
            })
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
             data.map((val) => {
                if (val.SK === "PROFILE"){
                  stats.numPrompts = val.prompts;
                  stats.numViews = val.views;
                  setMyStats({...stats})
                }
             })
             setTopPrompts(data);
             setLastKey(data[data.length-1].prompt_id)
             setLoading(false);
          })
          .catch((err) => {
             console.log(err.message);
          });
    
        } catch (e) {
          console.log(e.message);
        }
      };
      useEffect(() => {
        getUserMetadata("");
        getUserPrompts("")
        console.log(startKeys)
      }, [getAccessTokenSilently, user?.sub]);
    

    
      const handleBefore = () => {
        console.log(startKeys)
        getUserPrompts(startKeys.pop())
    
      }
      const handleNext = () => {
        console.log(lastKey)
        startKeys.push(prompts[0].prompt_id)
        getUserPrompts(lastKey)
      }
  return (

    <Grid   
  container
  spacing={1}

  alignItems="center"
  justifyContent="center" 
 >
      <Grid item xs={8} md={8 } lg={8} xl={8}>
            <Accordion defaultExpanded={true} onChange={handleChange('panel1')} sx={{backgroundColor:"white"}} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    defaultExpanded={true}
                >
                  
                   <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.main
                    }}
                  >
                   
                  </Avatar>
                    <Typography sx={{ width: '33%', marginLeft: '10px' }} variant="h4">
                   @samairtimer
                   <IconButton style={{ marginLeft: "15px", }} color="primary" size="small">
          <ShareIcon fontSize="small" />
        </IconButton>
        <Divider sx={{marginTop: "10px"}} />
        <Stack direction={{xs:"column", md:"row"}} spacing={2} sx={{marginTop:"30px"}} >
        <TotalIncomeLightCard title={"Prompts"} stat={stats.numPrompts} image={1}></TotalIncomeLightCard>
        <TotalIncomeLightCard title={"Views"} stat={stats.numViews} image={3}></TotalIncomeLightCard>
        </Stack>
        </Typography> 
       
                </AccordionSummary>
             
                <AccordionDetails>
              
    
        {/* <Rating name="size-small" defaultValue={Math.floor(Math.random() * (5 - 1 + 1)) + 1} size="small"
           /> */}
           <Typography  variant="h4">Most Viewed Prompts</Typography>
                <Divider style={{ marginBottom: "20px" , marginTop: "10px"}} />
                <Grid item xs={4} sm={4} md={10}>
                                <Grid container item spacing={2} columns={{ xs: 4, sm: 2, md: 8 , lg:8, xl:8 }}>
                                    {topPrompts.map((prompt, index) => (
                                      prompt.prompt_body && <>
                                        <Grid xs={4} sm={2} md={2} lg={2} xl={2}>


                                                    <UpgradePlanCard promptObj={prompt} ></UpgradePlanCard>
                                          
                                        </Grid>
                                        </>
                                    ))}
                                </Grid>
                               
                          
                        </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={true} onChange={handleChange('panel4')} elevation={0}> 
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h4">All Prompts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Divider style={{ marginBottom: "20px" }} />

                    <Grid item xs={8} sm={2} md={10}>
              
                                <Grid container item spacing={2} columns={{ xs: 8, sm: 8, md: 8 , lg:8, xl:8 }}>
                               
                                    {prompts.map((prompt, index) => (
                                        <Grid xs={4} sm={2} md={2} lg={2} xl={2}>

                                        
                                                    <UpgradePlanCard promptObj={prompt} ></UpgradePlanCard>
                                          
                                        </Grid>
                                    ))}
                                 
                                </Grid>
                               
                                <IconButton onClick={handleBefore} ><NavigateBeforeIcon></NavigateBeforeIcon></IconButton>
        <IconButton ><NavigateNextIcon onClick={handleNext}></NavigateNextIcon></IconButton>
                        </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </Grid>

  )
  };

