import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, ButtonBase, Avatar, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useNavigate } from 'react-router-dom';
import LoginButton from 'auth/login';

// auth
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from 'auth/logout';

// assets
import { IconMenu2 } from '@tabler/icons';


// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#ffffff !important",
        boxShadow: "none !important",
      },
      "&:active": {
        boxShadow: "none !important",
        backgroundColor: "#3c52b2 !important",
      },
    },
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {/* logo & toggler button */}
     

        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', display: { md: 'none' } }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
  
          <LogoSection />




      {/* header search */}
      {/* <SearchSection sx={{margintRight: '10px'}} /> */}


      {/* notification & profile */}
      {!matchDownMd &&
        <Button variant="text"  sx={{marginLeft:"50px"}} onClick={() => navigate("/browse")}>Browse Prompts</Button>}
        {isAuthenticated && !matchDownMd && <Button marginLeft="10px" variant="text" 
        onClick={() => navigate("/utils/util-typography")}>Add Prompt</Button>}
       

        {isAuthenticated && !matchDownMd &&  <Button variant="text" onClick={() => navigate("/utils/util-typography")}>Favourites</Button>}
      {isAuthenticated && !matchDownMd  && <Button variant="text" onClick={()=> navigate("/myprompts")}  sx={{marginRight:"50px"}} >My Prompts</Button>}
{isAuthenticated && !matchDownMd ?<> <ProfileSection ></ProfileSection></>: <LoginButton></LoginButton>}

    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
