// material-ui
import { Grid,Typography} from '@mui/material';

// project imports

import ControlledAccordions from './ProfileSections';
import SubCard from 'ui-component/cards/SubCard';
import profile from 'assets/images/profile.gif';

// ==============================|| SAMPLE PAGE ||============================== //



const ProfilePage = () => (



    <Grid   item
  container
  spacing={2}

  alignItems="center"
  justifyContent="center" >
      <Grid item xs={12} md={8}>
    
      <Typography style={{marginTop: "30px"}}variant="h4" >   <img src={profile} alt="GPT2Code" width="50"/> </Typography>
       
        <ControlledAccordions sx={{marginTop: "100px"}} ></ControlledAccordions>
     
      </Grid>
     
    </Grid>


);

export default ProfilePage;
