// material-ui
import { styled } from '@mui/material/styles';
import { Card, CardContent, Grid, Typography, CardActionArea, Avatar, Chip, CardMedia, Skeleton } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';

import { useNavigate } from "react-router-dom";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: 'white',
  marginTop: '16px',
  marginBottom: '16px',
  marginLeft: '10px',
  overflow: 'hidden',
  position: 'relative',
  minWidth: '200px',
  borderRadius: "10px",
  boxShadow: '0 8px 20px 0 rgb(32 40 45 / 5%)',
}));
import logo from 'assets/images/gpt.png';
import gptLogo from 'assets/images/ChatGPT-Logo.png';
import svg from 'assets/images/card_bg.png';
// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = ({ promptObj,isLoading }) => {
  const navigate = useNavigate();
  return (
    <CardStyle variant="outlined">
      <CardMedia
        image={svg}

      >
        <CardActionArea onClick={() => navigate("/prompt", { state: { id: promptObj.prompt_id } })} sx={{ height: "150px" }}>
          <CardContent>
            <Grid container direction="column" >
              <Grid item xs={4} md={4} lg={4} >
                <Typography variant='h6' sx={{ marginTop: "10px", overflow: "hidden", display: "-webkit-box", 
                textOverflow: "ellipsis", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }} 
                 >{!isLoading && promptObj ? promptObj.prompt_name : <><Skeleton></Skeleton></>}</Typography>
              </Grid>
             
            </Grid>
            </CardContent>
          <Grid container  xs={4} md={8} lg={8} spacing={{md:1,lg:1}}  >
                <Grid item xs={4} md={4} lg={4} > 
                <Avatar alt="Natacha" src={gptLogo} />
                   </Grid>
                <Grid item xs={4} md={4} lg={4}  >
               
               
                   </Grid>
              </Grid>
              
        </CardActionArea>
        
      </CardMedia>
    </CardStyle>
  )
};

export default UpgradePlanCard;
