// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Chip,
  Snackbar,
  Skeleton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';
// assets
import { IconBrandTelegram, IconMailbox } from '@tabler/icons';
import ReadMoreIcon from '@mui/icons-material/ReadMore';


import { useNavigate } from "react-router-dom";

import LinkIcon from '@mui/icons-material/Link';
import AlertDialogSlide from 'ui-component/dialog';
import { useAuth0 } from "@auth0/auth0-react";
import { DeleteOutline } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const PromptList = ({ myPrompts, isLoading }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [isCopying, setIsCopying] = React.useState(false);
  const [slectedPrompt, setPrompt] = React.useState('');
  const [snackOpen, setSnackOpen] = React.useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const [delConfirm, setDelConfirm] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleClick = (promptId) => {
    console.log('The link was clicked.' + promptId);
    navigate("/prompt", { state: { id: promptId } })
  }

  const handleShare = (prmpt) => {
    console.log('The link was clicked.' + prmpt);
    setOpen(true);
    setPrompt(prmpt)
   

  }
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleClose = () => {
    setOpen(false);
    setDelConfirm(false)
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
};

  const handleDel = (prmpt) => {
    setPrompt(prmpt)
    setDelConfirm(true)


  }
  const handleDelInternal = (prmpt) => {
    console.log('The link was clicked.' + prmpt);
    deletePrompt(prmpt)
    setDelConfirm(false)
    setMessage("Prompt deleted sucessfully!")
    setSnackOpen(true)

  }
  const deletePrompt = async (prmpt) => {

    try {
      const accessToken = await getAccessTokenSilently();
  
      fetch(`https://bh2y51flh9.execute-api.us-east-1.amazonaws.com/staging/prompt?userId=${prmpt.PK.substring(5)}&promptId=${prmpt.prompt_id}`,
      {method:'DELETE',
       headers: {'Authorization': `Bearer ${accessToken}`}
        })
      .then((response) => response.json())
      .then((data) => {
         myPrompts = myPrompts.splice(myPrompts.indexOf(prmpt));
         setLoading(false);
   
      })
      .catch((err) => {
         console.log(err.message);
      });

    } catch (e) {
      console.log(e.message);
    }
  };

  const copyToClipboard = async (prompt_id) => {
    await sleep(2000)
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText("https://goprompts.in/prompt?id="+prompt_id);
    } else {
      document.execCommand("copy", true, "ads");
    }
    setCopied(true);
    setIsCopying(false)
  }
  const handleCopyLink = (prompt_id) => {
      setIsCopying(true)
      copyToClipboard(prompt_id)
      
      setMessage("Link Copied to CLipboard")
      setTimeout(()=> { setOpen(false);setCopied(false);setSnackOpen(true) }, 5000)

    }


  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: '0 6px'
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: '5px'
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28
  };

  return (
    <>
      <List
        sx={{
          width: '75%',

          py: 0,
          borderRadius: '10px',
          marginTop: '10px',
          [theme.breakpoints.down('md')]: {
            maxWidth: 300
          },
          '& .MuiListItemSecondaryAction-root': {
            top: 22
          },
          '& .MuiDivider-root': {
            my: 0
          },
          '& .list-container': {
            pl: 7
          }
        }}
      >
        {isLoading && 
        
        [1,2,3,4].map(val => (<>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton
        animation="wave"
        height={20}
        width="50%"
        style={{ marginBottom: 20 }}
      />
        <Skeleton
        animation="wave"
        height={20}
        width="50%"
        style={{ marginBottom: 20 }}
      />
      <Divider /></>
        ))}
        {!isLoading && myPrompts.map(prompt => (
          <><ListItemWrapper   >
            <ListItem alignItems="center" onClick={() => { handleClick(prompt.prompt_id) }}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary.light,
                    border: 'none',
                    borderColor: theme.palette.primary.main
                  }}
                >
                  <IconMailbox stroke={1.5} size="1.3rem" />
                </Avatar>
              
              </ListItemAvatar>
              <ListItemText primary={<Typography variant="subtitle1">{prompt.prompt_name}</Typography>} />
         
            </ListItem>
            <Grid container direction="column" className="list-container">
              <Grid item xs={12} sx={{ pb: 2 }}>
                <Typography variant="subtitle2">{prompt.prompt_description}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
               
                  <Grid item>
                    <Button variant="outlined" onClick={() => { handleShare(prompt) }} disableElevation endIcon={<IconBrandTelegram stroke={1.5} size="1.3rem" />}>
                      Share
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => { handleDel(prompt) }} disableElevation endIcon={<DeleteOutline stroke={1.5} size="1.3rem" />}>
                      Del
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItemWrapper>
            <Divider />


            
          </>
        ))}

      </List>
      <Dialog
        sx={{ backdropFilter: "blur(3px)"}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        hideBackdrop={true}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><Typography variant="h3"></Typography></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <Typography variant="h6">{slectedPrompt.prompt_name}</Typography>
            {slectedPrompt.prompt_body}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
        { copied && <Chip label="Copied link to clipboard!" color="success" sx={{marginRight:"100px", color: 'white', borderRadius: "2px"}}/>}
          <LoadingButton variant="outlined"  loading={isCopying} disableElevation onClick={() => {handleCopyLink(slectedPrompt.prompt_id)}} startIcon={<LinkIcon />}>Copy Link</LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ backdropFilter: "blur(3px)"}}
        open={delConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        hideBackdrop={true}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><Typography variant="h3"></Typography></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <Typography variant="h4">Do you really want to delete the prompt?</Typography>
            
          <Typography sx={{margin:"20px"}}>{slectedPrompt.prompt_body}</Typography>
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button variant="contained"  disableElevation onClick={() => {handleDelInternal(slectedPrompt)}} >Yes</Button>
          <Button variant="outlined"  disableElevation onClick={handleClose} >No</Button>
  
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackOpen}
        onClose={handleSnackClose}
        autoHideDuration={6000}
        ContentProps={{
          sx: {
            background: "#51ccad"
          }
        }}
        message={message}
      />
    </>
  );
};

export default PromptList;
